import React from "react";
import { Link } from "gatsby";
import Image from "../Hacks/Image";
import Content from "../Hacks/Content";

export default class HeroIntro extends React.Component {
  render() {
    const {
      headerType,
      subtitle,
      title1,
      title2,

      massiveHero,

      excerpt,
      content,

      ctaLink,
      ctaText,
      cta2Link,
      cta2Text,

      image1,
      background,
    } = this.props;
    // var classes=false;

    return (
      <header
        className={`hero container-fluid pb-lg-5 bg-${
          background ? background : "primary"
        }`}
      >
        <div
          className={`row justify-content-center contained-xl ${
            image1 ? "" : "pt-lg-5"
          }`}
        >
          <div
            className={`
              align-self-center
              ${
                headerType === "LEFT"
                  ? "col-8 col-md-7 col-lg-5 pt-lg-5 order-lg-2 pb-4 pb-lg-0"
                  : "col-8 col-md-7 col-lg-5 pt-lg-3"
              }
              ${
                headerType === "CENTER" && image1
                  ? "pt-4 pt-lg-5 mb-4 mt-lg-5"
                  : ""
              }
            `}
          >
            {image1 && <Image image={image1} className="w-100" alt="" />}
          </div>

          {headerType === "CENTER" && <div className="col-12"></div>}

          <div
            className={`align-self-center ${
              headerType === "LEFT"
                ? "col-10 col-lg-5 text-center text-lg-left pb-lg-4 mb-3"
                : "col-10 col-lg-6 text-center"
            } pt-lg-5 pr-lg-3 order-lg-1 `}
          >
            {subtitle ? (
              <React.Fragment>
                <h1
                  className={`h4 mt-2 mb-0 mb-lg-2 text-black ${
                    headerType === "LEFT" ? "pr-lg-5" : ""
                  }`}
                >
                  {subtitle}
                </h1>
                {(title2 || title1) && (
                  <h2 className="h2">
                    {title1 && <div>{title1}</div>}
                    {title2 && (
                      <div
                        className={`text-${
                          background === "primary" ? "white" : "primary"
                        }`}
                      >
                        {title2}
                      </div>
                    )}
                  </h2>
                )}
              </React.Fragment>
            ) : (
              <React.Fragment>
                {(title2 || title1) && (
                  <h1
                    className={`${
                      massiveHero === "true" || massiveHero === true
                        ? "display-lg-3"
                        : "h2"
                    }`}
                  >
                    {title2 && <Content content={title2} />}
                    {title1 && (
                      <Content
                        className={`text-${
                          background === "primary" ? "white" : "primary"
                        }`}
                        content={title1}
                      />
                    )}
                  </h1>
                )}
              </React.Fragment>
            )}

            {excerpt && <Content className={`lead`} content={excerpt} />}
            {content && <Content className={``} content={content} />}

            {ctaLink && (
              <Link
                to={ctaLink}
                className={`btn btn-${
                  background !== "primary" ? "primary" : "white"
                } mt-3 mt-3 btn-lg main-cta ml-2 ml-lg-0 mr-2`}
              >
                {ctaText}
              </Link>
            )}
            {cta2Link && (
              <Link
                to={cta2Link}
                className={`btn btn-outline-danger mt-1 mt-3 btn-lg ml-2 ml-lg-0 main-cta mr-2`}
              >
                {cta2Text}
              </Link>
            )}
          </div>
        </div>
      </header>
    );
  }
}
